import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TableSortLabel,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Search } from '@mui/icons-material';

import styles from './index.module.css';

const patientData = [
  {
    firstName: 'John',
    lastName: 'Doe',
    phoneNumber: '123-456-7890',
    messageTemplate: 'Template 1',
    status: 'Sent',
  },
  {
    firstName: 'Jane',
    lastName: 'Smith',
    phoneNumber: '987-654-3210',
    messageTemplate: 'Template 2',
    status: 'Read',
  },
];

const messageTemplates = [
  { id: 1, name: 'Template 1' },
  { id: 2, name: 'Template 2' },
  { id: 3, name: 'Template 3' },
];

// const statuses = ["Sent", "Read", "Clicked", "Started", "Completed"];

export default function NotificationsTab() {
  const [patients, setPatients] = useState(patientData);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [searchQuery, setSearchQuery] = useState('');

  const handleTemplateChange = (index, template) => {
    const updatedPatients = [...patients];
    updatedPatients[index].messageTemplate = template;
    setPatients(updatedPatients);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedPatients = [...patients].sort((a, b) => {
    if (sortConfig.key) {
      const valueA = a[sortConfig.key].toString();
      const valueB = b[sortConfig.key].toString();
      if (valueA < valueB) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });

  const filteredPatients = sortedPatients.filter((patient) =>
    [patient.firstName, patient.lastName, patient.phoneNumber, patient.status].some((field) =>
      field.toLowerCase().includes(searchQuery.toLowerCase()),
    ),
  );

  return (
    <section className={styles.sectionColumn}>
      <FormControl>
        <InputLabel htmlFor="search">Search</InputLabel>
        <OutlinedInput
          id="search"
          label="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: '20px' }}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          }
        />
      </FormControl>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'firstName'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('firstName')}
                >
                  Patient&apos;s First Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'lastName'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('lastName')}
                >
                  Patient&apos;s Last Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'phoneNumber'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('phoneNumber')}
                >
                  Patient&apos;s Phone Number
                </TableSortLabel>
              </TableCell>
              <TableCell>Message Template (ID)</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.key === 'status'}
                  direction={sortConfig.direction}
                  onClick={() => handleSort('status')}
                >
                  Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPatients.map((patient, index) => (
              <TableRow key={index}>
                <TableCell>{patient.firstName}</TableCell>
                <TableCell>{patient.lastName}</TableCell>
                <TableCell>{patient.phoneNumber}</TableCell>
                <TableCell>
                  <Select
                    value={patient.messageTemplate}
                    onChange={(e) => handleTemplateChange(index, e.target.value)}
                    displayEmpty
                  >
                    {messageTemplates.map((template) => (
                      <MenuItem key={template.id} value={template.name}>
                        {template.name} (ID: {template.id})
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>{patient.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
}
