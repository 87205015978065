import { useState } from 'react';
import {
  Input,
  Button,
  TextField,
  Container,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  Grid2 as Grid,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import styles from './index.module.css';

export default function CompanyTab() {
  const [formData, setFormData] = useState({
    businessName: '',
    businessLogo: null,
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    phoneNumber: '',
  });

  const [passwordData, setPasswordData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({
    passwordMismatch: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePasswordChange = (e) => {
    setErrors({ passwordMismatch: false });
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    setFormData({
      ...formData,
      businessLogo: file,
    });
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();

    const res = await fetch('/api/db/clients', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ formData }),
    });
    console.log('Profile Saved Data:', res);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (passwordData.password !== passwordData.confirmPassword) {
      setErrors({ passwordMismatch: true });
      return;
    }

    await fetch('/api/db/clients', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ clientId: localStorage.getItem('clientId'), password: passwordData.password }),
    });

    console.log('Password Updated!', passwordData);
  };

  return (
    <section className={styles.section}>
      <Container maxWidth="sm" className={styles.container}>
        <h2>Company Profile</h2>
        <form onSubmit={handleProfileSubmit}>
          <Grid container spacing={2}>
            {/* Business Name */}
            <Grid size={12}>
              <TextField
                label="Business Name"
                name="businessName"
                fullWidth
                value={formData.businessName}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Business Logo */}
            <Grid size={12}>
              <Typography variant="subtitle1">Business Logo</Typography>
              <Input type="file" inputProps={{ accept: 'image/*' }} fullWidth onChange={handleFileChange} />
            </Grid>

            {/* First Name */}
            <Grid size={12}>
              <TextField
                label="First Name"
                name="firstName"
                fullWidth
                value={formData.firstName}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Last Name */}
            <Grid size={12}>
              <TextField
                label="Last Name"
                name="lastName"
                fullWidth
                value={formData.lastName}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Address */}
            <Grid size={12}>
              <TextField
                label="Address"
                name="address"
                fullWidth
                value={formData.address}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Email */}
            <Grid size={12}>
              <TextField
                label="Email"
                type="email"
                name="email"
                fullWidth
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Phone Number */}
            <Grid size={12}>
              <TextField
                label="Phone Number"
                name="phoneNumber"
                fullWidth
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
              />
            </Grid>

            {/* Submit Button */}
            <Grid size={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Save Profile
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>

      <Container maxWidth="sm" className={styles.container}>
        <h2>Change Password</h2>
        <form onSubmit={handlePasswordSubmit}>
          <Grid container spacing={2}>
            {/* Password */}
            <Grid size={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="password">New Password</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  value={passwordData.password}
                  onChange={handlePasswordChange}
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>

            {/* Confirm Password */}
            <Grid size={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                <OutlinedInput
                  id="confirm-password"
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={passwordData.confirmPassword}
                  onChange={handlePasswordChange}
                  required
                  error={errors.passwordMismatch}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirm password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            {errors.passwordMismatch ? <p>Passwords do not match</p> : <p>&nbsp;</p>}
            <Grid size={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Change Password
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </section>
  );
}
