import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Paper, IconButton, InputLabel, FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

import styles from './index.module.css';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (res.ok) {
      const data = await res.json();
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('clientId', data.clientId);
      navigate('/dashboard');
    } else {
      const { error } = await res.json();
      setRequestError(error);
      setLoading(false);
    }
  };

  const token = localStorage.getItem('authToken');
  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <main className={styles.main}>
      <img src="/health-of-your-space.png" alt="Health of Your Space" className={styles.logo} width={188} height={50} />

      <Paper elevation={2} className={styles.paper}>
        <h1>Dashboard Login</h1>
        <form onSubmit={handleSubmit}>
          <FormControl variant="outlined" className={styles.form}>
            <FormControl>
              <InputLabel htmlFor="username">Username</InputLabel>
              <OutlinedInput
                required
                id="username"
                name="username"
                label="Username"
                type="text"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setRequestError(null);
                }}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                required
                id="password"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                error={!!requestError}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setRequestError(null);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {requestError ? <p>{requestError}</p> : <p>&nbsp;</p>}
            <LoadingButton type="submit" loading={loading} disabled={loading} variant="contained" loadingPosition="end">
              Login
            </LoadingButton>
          </FormControl>
        </form>
      </Paper>
    </main>
  );
};

export default Login;
