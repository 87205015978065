import { useState } from 'react';
import { Box, Select, MenuItem, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import styles from './index.module.css';

const MessagingTab = ({ patients }) => {
  const [selectedPatientId, setSelectedPatientId] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const patientId = e.target.value;
    setSelectedPatientId(patientId);

    const patient = patients.find((p) => p.Id === patientId);
    setSelectedPatient(patient || null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!selectedPatient || !message.trim()) {
      alert('Please select a patient and write a message.');
      setLoading(false);
      return;
    }

    const phoneNumber =
      selectedPatient.PhoneNumbers && selectedPatient.PhoneNumbers[0] ? selectedPatient.PhoneNumbers[0].Number : 'N/A';

    const smsMessage = `Hello ${selectedPatient.FirstName} ${selectedPatient.LastName}!,\n${message}`;

    const token = localStorage.getItem('authToken');
    await fetch('/api/sms/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ phoneNumber, smsMessage }),
    });

    setMessage('');
    setLoading(false);
  };

  return (
    <section className={styles.sectionColumn}>
      <Box sx={{ p: 3, pt: 0 }}>
        <h2>Select Patient to Message</h2>
        <Select value={selectedPatientId} onChange={handleChange} displayEmpty fullWidth sx={{ mb: 6 }}>
          <MenuItem value="" disabled>
            Choose a patient
          </MenuItem>
          {patients.map((patient) => (
            <MenuItem key={patient.Id} value={patient.Id}>
              {patient.FirstName} {patient.LastName}
            </MenuItem>
          ))}
        </Select>

        {selectedPatient && (
          <>
            <h2>Patient Message Details</h2>
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                label="Name"
                fullWidth
                margin="normal"
                className={styles.readOnly}
                value={`${selectedPatient.FirstName} ${selectedPatient.LastName}`}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label="Phone Number"
                fullWidth
                margin="normal"
                className={styles.readOnly}
                value={
                  selectedPatient.PhoneNumbers && selectedPatient.PhoneNumbers[0] ? selectedPatient.PhoneNumbers[0].Number : 'N/A'
                }
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label="Message"
                fullWidth
                multiline
                rows={4}
                placeholder="Enter a message to send via Twilio"
                margin="normal"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <LoadingButton type="submit" loading={loading} disabled={loading} variant="contained" loadingPosition="end">
                Send Message
              </LoadingButton>
            </Box>
          </>
        )}
      </Box>
    </section>
  );
};

export default MessagingTab;
