import { useState } from "react";

import styles from './index.module.css';

const TemplatesTab = () => {
  const [value, setValue] = useState({});

  return (
    <section className={styles.section}>
      <h2>Message Templates</h2>
    </section>
  );
}

export default TemplatesTab;
