import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid2 as Grid, Paper, Tab, Tabs } from '@mui/material';

import CompanyTab from './company.tab';
import TemplatesTab from './templates.tab';
import MessagingTab from './messaging.tab';
import NotificationsTab from './notifications.tab';

import styles from './index.module.css';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    const getPatients = async () => {
      const token = localStorage.getItem('authToken');
      const res = await fetch('/api/patients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.ok) {
        const data = await res.json();
        setPatients(data.patients);
      }
    };

    getPatients();
  }, []);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleLogout = () => {
    navigate('/logout');
  };

  return (
    <main className={styles.main}>
      <header className={styles.header}>
        <Grid container spacing={0} alignItems="center" justifyContent="space-between">
          <Grid>
            <img
              src="/health-of-your-space.png"
              title="Health of Your Space"
              alt="Health of Your Space"
              className={styles.logo}
              width={188}
              height={50}
            />
          </Grid>
          <Grid>
            <h1>Dashboard</h1>
          </Grid>
          <Grid>
            <Button variant="outlined" onClick={handleLogout}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </header>
      <Paper elevation={2} className={styles.paper}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={styles.tabsBg}>
          <Tabs value={value} onChange={handleChange} aria-label="Dashboard Navigation">
            <Tab label="Company Profile" {...a11yProps(0)} className={styles.tabs} />
            <Tab label="Notification Status" {...a11yProps(1)} className={styles.tabs} />
            <Tab label="Message Templates" {...a11yProps(2)} className={styles.tabs} />
            <Tab label="Send a Message" {...a11yProps(3)} className={styles.tabs} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CompanyTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <NotificationsTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <TemplatesTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <MessagingTab patients={patients} />
        </CustomTabPanel>
      </Paper>
    </main>
  );
};

export default Dashboard;
